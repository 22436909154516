
import React, { useState, useRef, useEffect } from 'react'
import apartment from '../data/apartmenData';
import cityData from '../data/cityData';
import rentData from "../data/rentData";
import { useLocation } from 'react-router-dom';
import villages from "../data/villages";
import landmarks from "../data/landmarks";
import metros from "../data/metros";
import districtData from '../data/districtData';
import trans from "../lang/translations";
const url = document.getElementById('root').getAttribute('data-url');
const queries = document.getElementById('root').getAttribute('data-query');
const opacity = url === '/' ? 0.6 : 1;

const Search = () => {
    const lang = document.getElementById("root").dataset.lang
    const [searchQuery, setSearchQuery] = useState("")
    const [showRent, setShowRent] = useState(false)
    const [showApart, setShowApart] = useState(false);
    const [roomCount, setRoomCount] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [priceInput, setPriceInput] = useState(false);
    const [fieldInput, setFieldInput] = useState(false);
    const [sotFieldInput, setSotFieldInput] = useState(false);
    const selectedOption = rentData.find((data) => data.id == 1);
    const [selectRent, setSelectRent] = useState(selectedOption[`rent_name_${lang}`]);
    const [chooseRent, setChooseRent] = useState("1")
    const selectValue = apartment.find((data) => data.id == 0);
    const [selectedApartment, setSelectedApartment] = useState(selectValue[`apartmentName_${lang}`]);
    const [selec, setSelec] = useState(0)
    const [selectedCity, setSelectedCity] = useState([]);
    const searchInputRef = useRef("");
    const [selectedLandmarks, setSelectedLandmarks] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [selectDistric, setSelectDistric] = useState([]);
    const [selectedMetro, setSelectedMetro] = useState([]);
    const detailWrapperRef = useRef("");
    const rentWrapperRef = useRef("");
    const roomWrapperRef = useRef("");
    const apartWrapperRef = useRef("");
    const priceWrapperRef = useRef("");
    const fieldWrapperRef = useRef("");
    const sotWrapperRef = useRef("");
    const searchWrapperRef = useRef("");
    const refStatePairs = [
        { ref: detailWrapperRef, stateSetter: setShowDetail },
        { ref: rentWrapperRef, stateSetter: setShowRent },
        { ref: roomWrapperRef, stateSetter: setRoomCount },
        { ref: apartWrapperRef, stateSetter: setShowApart },
        { ref: priceWrapperRef, stateSetter: setPriceInput },
        { ref: fieldWrapperRef, stateSetter: setFieldInput },
        { ref: sotWrapperRef, stateSetter: setSotFieldInput },
    ];
    const handleRentSelect = (id) => {
        const selectedOption = rentData.find((data) => data.id == id);
        setSelectRent(selectedOption[`rent_name_${lang}`]);
        setChooseRent(selectedOption.id);
        setShowRent(false);
    };
    const handleDocumentClick = (e) => {
        for (const { ref, stateSetter } of refStatePairs) {
            if (ref.current && !ref.current.contains(e.target)) {
                stateSetter(false);
            }
        }
    };
    document.addEventListener('click', handleDocumentClick);
    const handleResetStates = (stateToToggle) => {
        setShowRent(stateToToggle == 'showRent' ? !showRent : false);
        setShowApart(stateToToggle == 'showApart' ? !showApart : false);
        setRoomCount(stateToToggle == 'roomCount' ? !roomCount : false);
        setPriceInput(stateToToggle == 'priceInput' ? !priceInput : false);
        setFieldInput(stateToToggle == 'fieldInput' ? !fieldInput : false);
        setSotFieldInput(stateToToggle == 'sotFieldInput' ? !sotFieldInput : false);
        setShowDetail(stateToToggle == 'showDetail' ? !showDetail : false);
    };
    const handleCheckApartment = (id) => {
        const selectedValue = apartment.find((data) => data.id == id);
        setSelectedApartment(selectedValue[`apartmentName_${lang}`]);
        setSelec(selectedValue.id);
        setShowApart(false);
    };
    const clearAllChecked = () => {
        setSelectedCity("")
        setSelectDistric("")
        setSelectedRegion("")
        setSelectedMetro("")
        setSelectedLandmarks("")
        setSelectRent(selectedOption[`rent_name_${lang}`])
        setChooseRent("1")
        setSelectedApartment(selectValue[`apartmentName_${lang}`])
        setSelec("0");
        setShowDetail(false)
    }
    const handleCityClick = (id) => {
        const isSelectedCity = selectedCity.includes(id);
        
        if (isSelectedCity) {
            const updatedCity = selectedCity.filter((selectCity) => selectCity != id);
            setSelectedCity(updatedCity);
        } else {
            setSelectedCity([...selectedCity, id]);
        }
        searchInputRef.current.focus()
    }
    const handleRegionClick = (id) => {
        const isSelectedRegion = selectedRegion.includes(Number(id));
        if (isSelectedRegion) {
            const updatedRegion = selectedRegion.filter((selectRegion) => selectRegion != id);
            setSelectedRegion(updatedRegion);
        } else {
            setSelectedRegion([...selectedRegion, id]);
        }
        searchInputRef.current.focus()
    };
    const handleDistrictClick = (id) => {
        const isSelectedDistrict = selectDistric.includes(Number(id));
        if (isSelectedDistrict) {
            const updatedDistricts = selectDistric.filter((districtId) => districtId != id);
            setSelectDistric(updatedDistricts);
        } else {
            setSelectDistric([...selectDistric, id]);
        }
        searchInputRef.current.focus()
    };
    const handleMetroClick = (id) => {
        const isSelected = selectedMetro.includes(Number(id));
        if (isSelected) {
            const updatedMetro = selectedMetro.filter((selectedMetro) => selectedMetro != id);
            setSelectedMetro(updatedMetro);
        } else {
            setSelectedMetro([...selectedMetro, id]);
        }
        searchInputRef.current.focus()
    };
    const handleLandmarkClick = (id) => {
        const isSelected = selectedLandmarks.includes(Number(id));
        if (isSelected) {
            const updatedLandmarks = selectedLandmarks.filter((selectedLandmark) => selectedLandmark != id);
            setSelectedLandmarks(updatedLandmarks);
        } else {
            setSelectedLandmarks([...selectedLandmarks, id]);
        }
        searchInputRef.current.focus()
    }
    const clearSearch = () => {
        setSearchQuery("")
    }
    
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('type') ) {
            const type = queryParams.get('type');
            
            const prefixRooms = 'rooms';
            const RoomIdParams = [...queryParams.entries()]
                .filter(([key]) => key.startsWith(`${prefixRooms}[`));
            const rooms = [];
            RoomIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? matches[1] : rooms.length;
                rooms[index] = value;
            });

            const prefixCity = 'city_id';
            const CityIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixCity}[`));
            const cityid = [];
            CityIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? parseInt(matches[1]) : cityid.length;
                cityid[index] = parseInt(value);
            });

            const prefixDist = 'district_id';
            const DistIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixDist}[`));
            const districtid = [];
            DistIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? parseInt(matches[1]) : districtid.length;
                districtid[index] = parseInt(value);
            });

            const prefixVillage = 'villageid';
            const VillageIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixVillage}[`));
            const villageid = [];
            VillageIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? matches[1] : villageid.length;
                villageid[index] = parseInt(value);
            });

            const prefixMetro = 'metroid';
            const MetroIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixMetro}[`));
            const metroid = [];
            MetroIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? matches[1] : metroid.length;
                metroid[index] = parseInt(value);
            });

            const prefixLandmark = 'landmarkid';
            const LandmarkIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixLandmark}[`));
            const landmarkid = [];
            LandmarkIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? parseInt(matches[1]) : landmarkid.length;
                landmarkid[index] = parseInt(value);
            });

            const prefixRepair = 'repair';
            const RepairIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixRepair}[`));
            const repair = [];
            RepairIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? matches[1] : repair.length;
                repair[index] = parseInt(value);
            });

            const prefixofficetype = 'officetype';
            const OfficetypeIdParams = [...queryParams.entries()]
            .filter(([key]) => key.startsWith(`${prefixofficetype}[`));
            const officetype = [];
            OfficetypeIdParams.forEach(([key, value]) => {
                const matches = key.match(/\[(\d*)]/);
                const index = matches && matches[1] !== '' ? matches[1] : officetype.length;
                officetype[index] = parseInt(value);
            });
            const category = queryParams.get('category');
            setSelectedCity(cityid)
            setSelectDistric(districtid)
            setSelectedRegion(villageid)
            setSelectedMetro(metroid)
            setSelectedLandmarks(landmarkid)
            setSelectRent(rentData[type - 1][`rent_name_${lang}`])
            setChooseRent(type)
            if (category == 9) {
                setSelectedApartment(apartment[4][`apartmentName_${lang}`]);
                setSelec(9)
            } else if (category >= 4) {
                setSelectedApartment(apartment[Number(category) + 1][`apartmentName_${lang}`]);
                setSelec(Number(category));
            } else {
                setSelectedApartment(apartment[category][`apartmentName_${lang}`]);
                setSelec(category)
            }

        } else {

        }
    }, []);
    useEffect(() => {
        function handleClickOutside(event) {
            if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target)) {
                setSearchQuery('');
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchWrapperRef]);

    return (
        <div className="container">
        <form action={lang==="az" ? "/elanlar" : `/${lang}/elanlar`} method="GET" autoComplete='off' encType="multipart/form-data">
            <div className="wx-search-container-section">
                <div className='wx-search-container'>
                    <div className='row wx-search-row'>                        
                        <div className='col-12 p-1'>
                            <div className='wx-search' id='menu' ref={rentWrapperRef} onClick={() => handleResetStates('showRent')}>
                                <div className='wx-rent-wrapper' >
                                    <div className='wx-rent-input d-flex align-items-center justify-content-center'>
                                        <input type="text" className='form-control' placeholder={selectRent} readOnly />
                                        <input name="type" type="hidden" value={chooseRent} />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className={`bi bi-chevron-down ${showRent ? 'icon-rotate' : "icon"}`} viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    <div className={`wx-rent-list wx-list wx-list-style ${showRent ? 'active' : 'deactive'}`}>
                                        {rentData.map((data, id) => {
                                            return <ul key={id}>
                                                <li onClick={() => handleRentSelect(Number(data.id))}>
                                                    {data[`rent_name_${lang}`]}
                                                    <svg style={{ display: chooseRent == Number(data.id) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                        className='bi bi-check'
                                                        fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                    </svg>
                                                </li>
                                            </ul>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 p-1'>
                            <div className='wx-search' id='wx-location-search' ref={searchWrapperRef} >
                                <div className="wx-search-section">
                                    <div className='wx-search-input d-flex'>
                                        <input className='wx-modal-input' type="text" placeholder={trans[`${lang}`].locationtitle}
                                            value={searchQuery} ref={searchInputRef}
                                            onChange={(e) => setSearchQuery(e.target.value)} />
                                        {searchQuery ? (
                                            <div onClick={clearSearch}>
                                               <img src="/icons/close.png" alt="map pin" width={30}/></div>) : (
                                            <div>
                                                <img src="/icons/search-location.png" alt="map pin" width={30}/>
                                            </div>)}
                                    </div>
                                    <div className="wx-search-result-section">
                                        <ul>
                                            {searchQuery && (
                                                districtData
                                                    .filter((district) =>
                                                        district[`districtname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase()))
                                                    .map((district, index) => (
                                                        <li key={index} onClick={() => handleDistrictClick(Number(district.district_id))}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{district[`districtname_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].district}</p>
                                                            </div>
                                                            <svg style={{ display: selectDistric.includes(Number(district.district_id)) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    ))
                                            )}
                                            {searchQuery && (
                                                villages
                                                    .filter((village) =>
                                                        village[`village_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                    )
                                                    .map((village, id) => (
                                                        <li key={id} onClick={() => handleRegionClick(Number(village.id))}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{village[`village_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].villiage}</p>
                                                            </div>
                                                            <svg style={{
                                                                display: selectedRegion.includes(Number(village.id)) ? "inline-block" : "none",
                                                            }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    ))
                                            )}
                                            {searchQuery && (
                                                metros
                                                    .filter((metro) =>
                                                        metro[`metro_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase()))
                                                    .map((metro, id) => (
                                                        <li key={id} onClick={() => handleMetroClick(Number(metro.id))}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{metro[`metro_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].metro}</p>
                                                            </div>
                                                            <svg style={{ display: selectedMetro.includes(Number(metro.id)) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                    )))}
                                            {searchQuery && (
                                                landmarks.filter((landmark) =>
                                                    landmark[`landmark_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).map((landmark, id) => (
                                                    <li key={id} onClick={() => handleLandmarkClick(Number(landmark.id))}>
                                                        <div className='d-flex flex-column wx-location-name-box '>
                                                            <p className='wx-location-name'>{landmark[`landmark_${lang}`]}</p>
                                                            <p className='wx-location-title'>{trans[`${lang}`].landmark}</p>
                                                        </div>
                                                        <svg style={{ display: selectedLandmarks.includes(Number(landmark.id)) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                            className='bi bi-check'
                                                            fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                        </svg>
                                                    </li>
                                                ))
                                            )}
                                            {searchQuery && (
                                                cityData
                                                    .filter((city) =>
                                                        city[`cityname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase()))
                                                    .map((city, index) => (
                                                        <li key={index} onClick={() => handleCityClick(city.city_id)}>
                                                            <div className='d-flex flex-column wx-location-name-box '>
                                                                <p className='wx-location-name'>{city[`cityname_${lang}`]}</p>
                                                                <p className='wx-location-title'>{trans[`${lang}`].city}</p>
                                                            </div>
                                                            <svg style={{ display: selectedCity.includes(city.city_id) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                className='bi bi-check'
                                                                fill="currentColor" viewBox="0 0 16 16">
                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                            </svg>
                                                        </li>
                                                ))
                                            )}
                                            {(searchQuery &&
                                                cityData.filter((city) =>
                                                    city[`cityname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                districtData.filter((district) =>
                                                    district[`districtname_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                villages.filter((village) =>
                                                    village[`village_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                metros.filter((metro) =>
                                                    metro[`metro_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0 &&
                                                landmarks.filter((landmark) =>
                                                    landmark[`landmark_${lang}`].toLowerCase().startsWith(searchQuery.toLowerCase())
                                                ).length == 0) && (
                                                    <li className="no-results"><p>{trans[`${lang}`].notfinedresult} <span className="fn-18"> &#9785;</span> </p></li>
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 p-1'>
                            <div className='wx-search' id='apartment' ref={apartWrapperRef} onClick={() => handleResetStates('showApart')}>
                                <div className='wx-apartment-wrapper'>
                                    <div className='wx-apartment-input d-flex align-items-center justify-content-center'>
                                        <input type="text" className='form-control' placeholder={selectedApartment} readOnly />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className={`bi bi-chevron-down ${showApart ? 'icon-rotate' : "icon"}`} viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    <div className={`wx-apartment-list wx-list wx-list-style ${showApart ? 'active' : 'deactive'}`} onClick={(e) => e.stopPropagation()}>
                                        <input type="hidden" name="category" value={selec} />
                                        <ul>
                                            {apartment.map((data, index) => {
                                                return <li onClick={() => handleCheckApartment(data.id)} key={index}>{data[`apartmentName_${lang}`]}
                                                    <svg style={{ display: selec == Number(data.id) ? "inline-block" : "none", color: "#43a5f5" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                        className='bi bi-check'
                                                        fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                    </svg>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 p-1'>
                            <div className='wx-search-foot'>
                                <div className='hiddenn'>
                                    <div className='wx-buttons white'>
                                        <div className='brdr1'>
                                            <a href={ "/msearch"+queries } className="btn text-white"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-adjustments" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M6 4v4" /><path d="M6 12v8" /><path d="M10 16a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M12 4v10" /><path d="M12 18v2" /><path d="M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M18 4v1" /><path d="M18 9v11" /></svg>&nbsp; {trans[`${lang}`].filter}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='wx-buttons'>
                                    <div onClick={clearAllChecked} style={{ opacity }} className="mt-1">
                                        <input type="reset" className="btn text-white height30" value={trans[`${lang}`].reset} />
                                    </div>
                                    <button className='btn wx-btn-show white'>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="22" height="22" viewBox="0 0 26 28" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
                                        <span>&nbsp; {trans[`${lang}`].search}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 p-1 mt-2">
                            <div className='wx-addAllChecked'>
                                <ul className='row'>
                                    {selectedCity &&
                                        selectedCity.filter((data) => data.id == cityData.city_id)
                                        .map((item, i) => (
                                            <li key={i} onClick={() => handleCityClick(Number(item))}>
                                                <input name='city_id[]' value={selectedCity[i]} type="hidden" />
                                                <div className='wx-box'>
                                                    <span>{cityData[item - 1][`cityname_${lang}`]}</span>
                                                    <span className='wx-title'>{trans[`${lang}`].city}</span>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x ms-2" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </li>
                                        ))
                                    }
                                    {selectDistric &&
                                        selectDistric.filter((data) => data.id == districtData.district_id).map((item, i) => (
                                            <li key={i} onClick={() => handleDistrictClick(Number(item))}>
                                                <input name='district_id[]' value={selectDistric[i]} type="hidden" />
                                                <div className='wx-box'>
                                                    <span>  {districtData[item - 1][`districtname_${lang}`]}</span>
                                                    <span className='wx-title'>{trans[`${lang}`].district}</span>
                                                </div>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    fill="currentColor"
                                                    className="bi bi-x mb-1"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </li>
                                        ))
                                    }
                                    {selectedRegion &&
                                        selectedRegion.filter((data) => data.id == villages.id)
                                            .map((item, i) => (
                                                <li key={i} onClick={() => handleRegionClick(Number(item))}>
                                                    <input name='villageid[]' value={selectedRegion[i]} type="hidden" />
                                                    <div className='wx-box'>
                                                        <span>{villages[item - 1][`village_${lang}`]}</span>
                                                        <span className='wx-title'>{trans[`${lang}`].villiage}</span>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x ms-2" viewBox="0 0 16 16">
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </li>
                                            ))
                                    }
                                    {selectedMetro &&
                                        selectedMetro.filter((data) => data.id == metros.id).map((item, i) => (
                                            <li key={i} onClick={() => handleMetroClick(Number(item))}>
                                                <input name='metroid[]' value={selectedMetro[i]} type="hidden" />
                                                <div className='wx-box'>
                                                    <span> {metros[item - 1][`metro_${lang}`]}</span>
                                                    <span className='wx-title'>{trans[`${lang}`].metro}</span>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x " viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </li>
                                        ))}
                                    {selectedLandmarks && selectedLandmarks.filter((data) => data.id == landmarks.id).map((item, i) => (
                                        <li key={i} onClick={() => handleLandmarkClick(Number(item))}>
                                            <input name='landmarkid[]' value={selectedLandmarks[i]} type="hidden" />
                                            <div className='wx-box'>
                                                <span>{landmarks[item - 1][`landmark_${lang}`]}</span>
                                                <span className='wx-title'>{trans[`${lang}`].landmark}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x mb-1" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        </div>
    );
};

export default Search;
